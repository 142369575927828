
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    height: 100%;
}

body {
    background-color: #ffffff;
}

#copyurl{
    background-color: #AFD89F;
    color: black;
}

.main-content {
    width: 100%;
    height: 100vh;
    position: relative;
}

.main-content h1 {
    font-size: 1em; /* 16px */
    margin: 0;
    padding: 0;
}

header {
    position: absolute;
    width: 100%;
    top: 0;
    padding: 1.0625em 0; /* 17px */
    background: rgba(255, 255, 255, .3);
    z-index: 999;
}


.agent-section {
    width: 100%;
    height: 100%;
    overflow: hidden;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.agent-section p.waiting {
    display: none;
}

.agent-section.placeholder {
    background: #CCCCCC;
    font-weight: bold;
}

.agent-section.placeholder p.waiting {
    display: block;
}

.agent-section video {
    width: 100% !important;
}

footer {
    position: absolute;
    width: 100%;
    bottom: 0;
    background: rgba(255, 255, 255, .3);
    z-index: 999;
}

.footer-elements {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.prospect-section {
    padding: 0.625em 0.9375em;
}

.prospect-section p.waiting {
    display: none;
}

.prospect-section.placeholder {
}

.prospect-section.placeholder p.waiting {
    display: block;
}

.prospect-section .prospect {
    float: left;
    display: none;/* By default, prospect videos off when agent logins*/
}

.content-section {
    display: table-cell;
    vertical-align: middle;
}

.prospect-section .prospect video {
    width: 100%;
    height: 100%;
    background-color: #000000;
    border: 1px solid #808080;
}

.prospect-section .prospect:first-child {
    margin-left: -0.9375em; /* -15px */
}

.prospect-section .prospect:last-child {
    margin-right: -0.9375em; /* -15px */

}

.prospect-section .prospect img {
    float: left;
    border: 0.0625em /* 1px */ solid #777;
    width: 100%;
}

.control-section {
    vertical-align: middle;
    float: right;
    padding: 0.5em 0.9375em;
}

.control-group-items {
    display: flex;
}

.control-section .actions {
    float: left;
    margin-left: 1.5625em; /* 25px */
    white-space: nowrap;
}

.control-section .actions .fa-video, .control-section .actions .fa-microphone, .fa-sync-alt {

}

.control-section .actions .fa-phone-square, .fa-sync-alt.back-cam {
    color: #dc3545;
}

.actions.fa-img {
    width: 2.5em;
    text-align: center;
    cursor: pointer;
}

.control-section .actions button {
    padding: 0.45em;
    width: 8.125em; /* 130px */
    font-weight: 600;
    letter-spacing: 0.0313em; /* .5px */
}

.react-confirm-alert-body {
    text-align: center;
    width: auto;
}

.react-confirm-alert-button-group {
    display: block;
}

.react-confirm-alert-button-group:after {
    display: block;
    clear: both;
    content: "";
}

.react-confirm-alert-button-group > button {
    width: 6.25em;
    font-size: 0.9375em;
}


.share-screen {
    z-index: 99;
    position: absolute;
}

.share-off {
    display: none;
}

.switch-camera-parentDiv {
    position: absolute;
    top: 4em;
    right: 1em;
    background: transparent;
}

/* Start - Login Screen */
.m-b-15 {
    margin-bottom: 0.9375em;
}

.or {
    position: relative
}

.or:before {
    content: '';
    width: 40%;
    height: 0.0625em;
    display: block;
    background: #e7e9ea;
    position: absolute;
    top: 0.6875em;
    right: 0.9375em;
}

.or:after {
    content: '';
    width: 40%;
    height: 0.0625em;
    display: block;
    background: #e7e9ea;
    position: absolute;
    top: 0.6875em;
    left: 0.9375em;
}

/* css for room title */
.title-text {
    color: black !important;
    font-size: 1.125em !important;
    font-weight: bold;
}

.temp-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 18.75em;

}

.temp-container p {
    font-weight: bold;
    font-size: 1.125em;
    color: #ff0000;
}

.self-share-overlay {
    background: rgba(255, 255, 255, .8);
    z-index: 100;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.self-share-overlay p {
    text-align: center;
    color: #ff0000;
    padding: 2em 1em;
    background: #FFFFFF;
    width: 100%;
    font-size: 16px;
}

.video-off {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    vertical-align: middle;
    border: 1px solid #808080;
    background-color: #FFFFFF;
    color: #808080;
    height: 15vh;
    width: 10vw;
}

.agent-section .video-off {
    background-color: transparent;
    display: none;
}

/* override alert dialog defaults */
.react-confirm-alert-overlay {
    background: rgba(0, 0, 0, 0.5);
}

.loader-popup .react-confirm-alert {
    background: white;
    width: 45%;
    padding: 1em;
    border-radius: 5px;
}

/* custom alert dialog styles */
.room-popup {
    min-height: 10em;
    display: flex;
    align-items: center;
    justify-content: center;
}

.room-popup h3 {
    text-align: center;
    margin-bottom: 15px;
    color: #212529;
    font-size: 26px;
}

.room-popup p.error-msg {
    color: #F00F00;
    font-size: 18px;
}

/* iPad Pro Portrait and Landscape */
@media only screen and (max-width: 64em
) {
    .control-section .actions {
        margin-left: 0.75em; /* 12px */
    }

    .control-section .actions button {
        padding: 0.375em; /* 6px */
        width: 5.625em; /* 90px */
    }

    .video-off {
        height: 4em;
    }

    .prospect-section .prospect video {
        width: 9.6vw!important;
    }
}

/* iPad Pro Portrait */
@media only screen
and (min-width: 64em)
and (max-height: 85.375em)
and (orientation: portrait)
and (-webkit-min-device-pixel-ratio: 1.5) {
    .agent-section video {
        width: auto;
        height: 100%;
    }

    .video-off{
        height: 4.75em;
    }
}

/* iPad Pro Landscape */
@media only screen
and (min-width: 64em)
and (max-height: 85.375em)
and (orientation: landscape)
and (-webkit-min-device-pixel-ratio: 1.5) {
    .agent-section video {
        width: 100%;
        height: auto;
    }

    .video-off{
        height: 6em!important;
    }
}

/* iPad Mini---------- */
@media only screen
and (min-device-width: 48em)
and (max-device-width: 64em)
and (orientation: portrait) {
    .agent-section video {
        width: auto!important;
        height: 100%;
    }

    .video-off{
        height: 4.75em!important;
    }
}

@media screen and (max-width: 49em
) and (max-height: 52em) and (orientation: landscape) {
    .agent-section video {
        height: 100%;
        width: auto;
    }


}

/* Phones/I-Phones and other small devices----------- */
@media screen and (max-width: 30em /* 480px */
) {
    .loader-popup .react-confirm-alert {
        width: 90%;
    }

    .agent-section video {
        height: 100%;
        width: auto;
    }

    footer {
        background: none;
    }

    .footer-elements {
        display: block;
    }

    .prospect-section .prospect {
        margin: 0.4688em; /* 7.5px */
    }

    .video-off {
        height: 3.75em;
        width: 100%;
    }

    .control-section {
        background: rgba(255, 255, 255, .3);
        padding: 0;
    }

    .control-group-items {
        display: block;
        padding: 0.4375em 2%; /* 7px */
        width: 100%;
    }

    .control-group-items .icon-group {
        padding: 0.125em 0; /* 2px */
        float: left;
    }

    .icon-group .actions:first-child {
        margin: 0; /* 15px */
    }

    .control-group-items .button-group {
        padding: 0;
        float: right;
    }

    .control-section .actions button {
        width: 5.625em; /* 90px */
        padding: 0.4375em; /* 7px */
        margin-top: 0.5em;
    }

    .control-section .actions {
        margin-left: 0.625em; /* 10px */
    }

    .fa-phone-square {
        margin-top: 0.1em;
    }
}

/* I-Phones 5/6/7/8 Pro ----------- */
@media only screen
and (min-device-width: 17.5em)
and (max-device-width: 46em)
and (orientation: landscape) {
    .agent-section video {
        width: 100%!important;
        height: auto;
    }

    .control-section .actions button {
        padding: 0.375em; /* 6px */
        width: 5.2em;
    }

    .btn-sm {
        font-size: 0.7rem !important;
    }
}

@media only screen
and (min-device-width: 17.5em)
and (max-device-width: 46em)
and (orientation: portrait) {
    .agent-section video {
        width: auto!important;
        height: 100%;
    }

    .control-section {
        padding: 0 !important;
    }

    .icon-group .actions:first-child {
        margin: 0;
    }

    .control-section .actions {
        margin-left: 0.4375em;
    }

    .btn-sm {
        font-size: .67rem;
    }

    .prospect-section .prospect video {
        width: 100% !important;
        height: 100%;
    }
}
/* Surface Duo */
@media only screen
and (width: 33.75em)
and (height: 45em)
and (orientation: portrait) {
    .prospect-section .prospect video {
        width: 45% !important;
        height: 100%;
    }
}

